import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';


function CRReportForm({ onSave, studyInstanceUID, modalityId, studies, placeholder }) {
  const navigate = useNavigate();

  const [isDisabled, setIsDisabled] = useState(true);
  const [showForm, setShowForm] = useState(true);
  const [content, setContent] = useState('Start typing...');
  const editor = useRef(null);

  useEffect(() => {
    fetch(`https://api-pacs.wazl.in/Template/TemplateData?inputId=${modalityId}`, {
      method: "POST"
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch template data');
        }
      })
      .then(data => {
        // console.log('Fetched data:', data);

        if (data && data.length > 0) {
          const templateData = {
            report: data[0].report,
          };

          // console.log(templateData.report);

          const replacedContent = replacePlaceholders(templateData.report, {
            name: "John Doe",
            patientid: "12345",
            RegisteredDate: "2024-04-27",
            age: "30",
            appointmentId: "67890",
            ReportedDate: "2024-04-27",
            gender: "Male",
            Drname: "Dr. Smith",
            Drsignature1: "Signature 1",
            Drsignature2: "Signature 2",
            Drsignature3: "Signature 3",
            DrName1: "Dr. John",
            DrName2: "Dr. Jane",
            DrName3: "Dr. Emily",
            Drdesignation1: "Radiologist",
            Drdesignation2: "Pathologist",
            Drdesignation3: "Cardiologist"
          });

          setContent(replacedContent);
          // setContent(templateData.report)
        }
      })
      .catch(error => {
        console.error('Error fetching template data:', error);
      });
  }, [modalityId]);

  const replacePlaceholders = (content, values) => {
    let replacedContent = content;
    for (const key in values) {
      const regex = new RegExp(`@\\(${key}\\)`, 'g');
      replacedContent = replacedContent.replace(regex, values[key]);
    }
    return replacedContent;
  };

  useEffect(() => {
    const savedData = localStorage.getItem(`${studyInstanceUID}_${modalityId}`);
    if (savedData) {
      setContent(savedData);
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [studyInstanceUID, modalityId]);

  const handleChange = (newContent) => {
    setContent(newContent);
  };



  const handleSave = () => {
    // console.log("Data Saved ...");
    onSave(content);
    localStorage.setItem(`${studyInstanceUID}_${modalityId}`, content);
  };

  const config = useMemo(
    () => ({
      readonly: false,
      placeholder: placeholder || 'Start typing...'
    }),
    [placeholder]
  );

  return (
    showForm && (
      <div className="card">
        <JoditEditor
          ref={editor}
          value={content}
          config={config}
          tabIndex={1} // tabIndex of textarea
          onChange={handleChange}
        />

        <div className="p-grid p-justify-between">
          <div className="flex flex-row mt-2">
            <button type="submit" onClick={handleSave} className=' bg-black text-white mr-2 rounded p-2'>Save</button>
            <button type="button" onClick={() => onSave('')} className=' bg-black text-white rounded p-2'>Cancel</button>
          </div>
        </div>
      </div >
    )
  );
}

CRReportForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  studyInstanceUID: PropTypes.string.isRequired,
  modalityId: PropTypes.string.isRequired
};


export default CRReportForm;
