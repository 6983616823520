import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import NavBar from '../NavBar';
import Svg from '../Svg';
import Icon from '../Icon';
import IconButton from '../IconButton';
import ReportIcon from '../EditorReport';

function Header({
  children,
  menuOptions,
  isReturnEnabled,
  onClickReturnButton,
  isSticky,
  WhiteLabeling,
  ...props
}): ReactNode {
  const { t } = useTranslation('Header');

  const onClickReturn = () => {
    if (isReturnEnabled && onClickReturnButton) {
      onClickReturnButton();
    }
  };

  // Check if the current URL contains the specified query parameter
  const isViewerPage = window.location.pathname === '/viewer' && window.location.search.includes('StudyInstanceUIDs');

  return (
    <NavBar
      className="justify-between border-b-4 border-black"
      isSticky={isSticky}
    >
      <div className="flex flex-1 justify-between items-center">
        <div className="flex items-center">
          <div
            className={classNames(
              'mr-3 inline-flex items-center',
              isReturnEnabled && 'cursor-pointer'
            )}
            onClick={onClickReturn}
            data-cy="return-to-work-list"
          >
            {isReturnEnabled && (
              <Icon
                name="chevron-left"
                className="text-primary-active w-8"
              />
            )}
            <div className="ml-4">
              <span style={{ color: 'white' }}>
                {WhiteLabeling?.createLogoComponentFn?.(React, props) || 'SPARK'}
              </span>
            </div>
          </div>
        </div>

      </div>
      {isViewerPage && (
        <div className="flex items-center justify-end">
          <ReportIcon />
        </div>
      )}
      <div className="flex items-center">{children}</div>
    </NavBar >
  );
}

Header.propTypes = {
  menuOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string,
      onClick: PropTypes.func.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  isReturnEnabled: PropTypes.bool,
  isSticky: PropTypes.bool,
  onClickReturnButton: PropTypes.func,
  WhiteLabeling: PropTypes.object,
  isActive: PropTypes.bool,
};

Header.defaultProps = {
  isReturnEnabled: true,
  isSticky: false,
  isActive: false,
};

export default Header;
