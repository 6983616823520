import React, { useState, useEffect } from 'react';
import { Icon, Dropdown, Tooltip } from '../../components';
import { useLocation } from 'react-router-dom';
import CRReportForm from './components/CRReportForm';

function ReportIcon() {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const studyInstanceUID = params.get('StudyInstanceUIDs');

  const [showCRReportForm, setShowCRReportForm] = useState(false);
  const [modalities, setModalities] = useState([]);
  const [selectedModalityId, setSelectedModalityId] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    fetch('https://api-pacs.wazl.in/Modality/Test', {
      method: 'POST'
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Failed to fetch modalities');
        }
      })
      .then(data => {
        console.log({ data });
        setModalities(data);
      })
      .catch(error => {
        console.error('Error fetching modalities:', error);
      });
  }, []);

  const handleModalityClick = (id) => {
    setSelectedModalityId(id);
    setIsDropdownOpen(true);
    setShowCRReportForm(true);
  };

  const handleSaveReport = (data) => {
    console.log("Data saved:", data);
    localStorage.setItem('patientReportData', (data));
    setShowCRReportForm(false);
  };

  const handleDropdownToggle = (isOpen) => {
    setIsDropdownOpen(isOpen);
    if (!isOpen) {
      setShowCRReportForm(false);
    }
  };

  return (
    <div className="group mx-0.5 mt-[8px] flex items-center">
      <div>
        <Dropdown
          id="patient-report-dropdown"
          showDropdownIcon={false}
          alignment="left"
          itemsClassName="text-primary-active"
          showBorders={false}
          maxCharactersPerLine={30}
          list={modalities.map(modality => ({
            title: modality.name,
            onClick: () => handleModalityClick(modality.id)
          }))}
          onToggle={handleDropdownToggle}
        >
          <Tooltip content="Reports">
            <div className='flex w-10 h-10 text-white  p-2 text-center items-center justify-center transition font-bold  rounded-md text-lg '>
              <Icon name="tab-patient-info" />
            </div>
          </Tooltip>
        </Dropdown>
      </div>

      {showCRReportForm && isDropdownOpen && (
        <div className="fixed  top-[56px] right-0 z-50 p-4">
          <div className="border border-gray-300 rounded bg-white p-4">
            <CRReportForm onSave={handleSaveReport} studyInstanceUID={studyInstanceUID}
              modalityId={selectedModalityId}
            />
          </div>
        </div>
      )}
    </div >
  );
}

export default ReportIcon;
